<template>
  <div>
    <h3 class="page-title">زاكاز باشقۇرۇش</h3>
    <el-divider></el-divider>
    <div class="rtl-right search-box">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-select v-model="filterForm.status" class="rtl-right" @change="toSearch()" clearable placeholder="ھالىتى">
            <el-option v-for="item in statusList" :key="item.name" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterForm.order_no" class="ltr-left" clearable placeholder="زاكاز نۇمۇرى"></el-input>
        </el-col>
        <el-col :span="5">
          <el-input
            v-model="filterForm.transaction_id"
            class="ltr-left"
            clearable
            placeholder="ئۈندىدار زاكاز نۇمۇرى"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterForm.user_name" class="ltr-left" clearable placeholder="ئەزا ئىسمى"></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filterForm.qrcode_user_name"
            class="ltr-left"
            clearable
            placeholder="ۋاكالەتچى ئىسمى"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-input
            v-model="filterForm.receiver_name"
            class="ltr-left"
            clearable
            placeholder="قۇبۇل قىلغۇچى ئىسمى"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            style="width:100%"
            class="ltr-left"
            v-model="filterForm.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            unlink-panels
            start-placeholder="باشلىنىش ۋاقتى"
            end-placeholder="ئاخىرلىشىش ۋاقتى"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
          <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار قىلىش </el-button>
        </el-col>
      </el-row>
    </div>

    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.details" border style="width: 100%">
              <el-table-column prop="order_detailable_type" label="تۈرى">
                <template slot-scope="scope">
                  <label v-show="scope.row.order_detailable_type == 'courses'">دەرس</label>
                  <label v-show="scope.row.order_detailable_type == 'agents'">ۋاكالەتچى</label>
                  <label v-show="scope.row.order_detailable_type == 'vips'">ئالاھىدە ئەزا</label>
                </template>
              </el-table-column>
              <el-table-column prop="orderDetailable.name_ug" label="ئىسمى"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="order_no" label="زاكاز نۇمۇرى"></el-table-column>
        <el-table-column prop="created_at" label="سېتىۋالغان ۋاقىت"></el-table-column>
        <el-table-column prop="payed_price" label="زاكاز سوممىسى"></el-table-column>
        <el-table-column prop="user.name" label="ئەزا ئىسمى"></el-table-column>
        <el-table-column prop="user.uid" label="UID"></el-table-column>
        <el-table-column prop="status" label="ۋاكالىتەن ساتقۇچى">
          <template slot-scope="scope">
            <label v-if="scope.row.qrcodeUser">{{ scope.row.qrcodeUser.name }}</label>
            <label v-else>-</label>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="دەرىجىسى">
          <template slot-scope="scope">
            <label v-if="scope.row.qrcodeUser">{{ scope.row.qrcodeUser.agent_name_ug }}</label>
            <label v-else>-</label>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status == 0">پۇل تۆلىمىگەن</label>
            <label v-show="scope.row.status == 1">پۇل تۆلىگەن</label>
            <label v-show="scope.row.status == 2">سوۋغا قىلغان</label>
          </template>
        </el-table-column>
        <el-table-column prop="is_gift" label="سوۋغاتمۇ">
          <template slot-scope="scope">
            <label v-show="scope.row.is_gift == 0">ياق</label>
            <label v-show="scope.row.is_gift == 1">ھەئە</label>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="current_page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="sizes,prev, pager, next,total,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .search-box {
    .el-select,
    .el-input,
    .el-date-editor {
      width: 100%;
    }
    [class*="el-col-"] {
      float: right;
    }
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        total: 0,
        pageTags: {},
        filterForm: {
          status: "",
          order_no: "",
          transaction_id: "",
          user_name: "",
          qrcode_user_name: "",
          receiver_name: "",
          timeRange: [],
        },
        statusList: [
          {
            name: "پۇل تۆلىمىگەن",
            value: "0",
          },
          {
            name: "پۇل تۆلىگەن",
            value: "1",
          },
          {
            name: "سوۋغا قىلغان",
            value: "2",
          },
        ],
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self
          .$fetch("admin/order", {
            page: self.current_page,
            per_page: self.per_page,
            status: self.filterForm.status,
            order_no: self.filterForm.order_no,
            transaction_id: self.filterForm.transaction_id,
            user_name: self.filterForm.user_name,
            qrcode_user_name: self.filterForm.qrcode_user_name,
            receiver_name: self.filterForm.receiver_name,
            start_time: self.filterForm.timeRange[0],
            end_time: self.filterForm.timeRange[1],
          })
          .then((response) => {
            if (response.status == 200) {
              self.tableData = response.data.data;
              self.total = response.data.meta.total;
              self.current_page = response.data.meta.current_page;
              self.last_page = response.data.meta.last_page;
            } else {
              console.log(response.message);
            }
            self.loading = false;
            if (JSON.parse(sessionStorage.getItem("curTags"))) {
              self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
            self.loading = false;
          });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      toClear() {
        self.filterForm = {
          status: "",
          order_no: "",
          transaction_id: "",
          user_name: "",
          qrcode_user_name: "",
          receiver_name: "",
          timeRange: [],
        };
        self.toSearch();
      },
      toSearch() {
        self.current_page = 1;
        self.getList();
      },
    },
  };
</script>
